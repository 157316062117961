import {DatasourceWFSAlias} from 'gmf/datasource/WFSAliases.js';
import {ServerType} from 'ngeo/datasource/OGC.js';
import {MapQuerent} from 'ngeo/query/MapQuerent.js';
import ngeoQueryAction from 'ngeo/query/Action.js';

/*
 * The aim of this override file is to enable QGIS QFS aliases on every layer
 * of a group (and not only for groups with only one layer !).
 *
 * Problem:
 * The problem in ngeo is that we build and get aliases from datasource, and
 * one datasource is for one "source". In case of layer group, we have one
 * datasource for multiple layer (or sources).
 * The collection of aliases work on multiple layers, but only "common"
 * attribute will be taken. (Example: "id" is in both layer, it will be taken,
 * "road" is only in one layer, it will be not taken). That's why it makes
 * no sens here and only group with one layer is kept.
 *
 * Solution:
 * In case of multiple layers: Build (hack) a side object in datasource
 * and rely on it to show aliases if this object exists (here:
 * https://github.com/camptocamp/ngeo/blob/2.6/src/query/MapQuerent.js#L242).
 */

// ======== Collect aliases ========

/**
 * Original work.
 * Works only with attributes already in the OGCServer (makes no
 * supplementary WFS request)
 * @param {import("ngeo/datasource/OGC.js").default} dataSource Data source.
 */
const addAliasesOnMultipleLayers = function(dataSource) {
  const aliases = {};
  const layerNames = dataSource.getWFSLayerNames();
  layerNames.forEach(layerName => {
    const tableAliases = {};
    aliases[layerName] = tableAliases;
    const ogcAttribute = dataSource.ogcAttributes_[layerName];
    Object.keys(ogcAttribute).forEach(name => {
      const attribute = ogcAttribute[name];
      tableAliases[name] = 'alias' in attribute ? attribute.alias : name;
    });
  });
  return aliases;
}

/**
 * COPIED and OVERRIDE from ngeo and modified to handle case
 * of dataSource.getWFSLayerNames().length > 1.
 * @param {import("ngeo/datasource/OGC.js").default} dataSource Data source.
 */
DatasourceWFSAlias.prototype.describe = function(dataSource) {
  // Only QGIS Server supports WFS aliases
  if (dataSource.ogcServerType !== ServerType.QGISSERVER ||
    dataSource.attributes
  ) {
    return;
  }
  if (dataSource.getWFSLayerNames().length > 1) {
    dataSource.tiAliases = addAliasesOnMultipleLayers(dataSource);
  }
  if (
    dataSource.wfsUrl_ &&
    dataSource.getWFSLayerNames().length == 1
  ) {
    // Trigger an additional WFS DescribeFeatureType request to get
    // datasource attributes, including aliases.
    this.ngeoDataSourcesHelper_.getDataSourceAttributes(dataSource);
  }
};

// ======== Collect aliases stop ========
// ======== Below: Display results ========

/**
 * Original work to take aliases from the new tiAliases attribute, or fallback
 * to the original ngeo code.
 *
 * @param {import("ngeo/datasource/OGC.js").default} dataSource Data source.
 * @param {import("ol/Feature.js").default<import("ol/geom/Geometry.js").default>} feature one queried feature.
 * @param {string} type The layer name of the feature.
 */
const setFeaturePropertiesWithAliases = function(dataSource, feature, type) {
  if (dataSource.tiAliases || (dataSource.attributes && dataSource.attributes.length)) {
    const properties = feature.getProperties();
    /** @type {Object<string, unknown>} */
    const filteredProperties = {};
    if (dataSource.tiAliases && dataSource.tiAliases[type]) {
      const nameAliases = dataSource.tiAliases[type];
      const properties = feature.getProperties();
      Object.keys(properties).forEach(propName => {
        const alias = nameAliases[propName];
        if (alias) {
          filteredProperties[alias] = properties[propName];
          feature.unset(propName, /* silent */ true);
        } else {
          // No alias is available => use the attribute as is.
          filteredProperties[propName] = properties[propName];
        }
      });
    } else {
      // Below: COPIED from ngeo
      dataSource.attributes.forEach((attribute) => {
        if (attribute.alias) {
          filteredProperties[attribute.alias] = properties[attribute.name];
          feature.unset(attribute.name, /* silent */ true);
        } else {
          // No alias is available => use the attribute as is.
          filteredProperties[attribute.name] = properties[attribute.name];
        }
      });
    }
    feature.setProperties(filteredProperties, /* silent */ true);
  }
};

/**
 * COPIED and OVERRIDE from ngeo and modified to handle layer group with
 * aliases. Check for OVERRIDE mention below.
 *
 * Called after a request to the querent service. Update the result.
 *
 * @param {string} action Query action
 * @param {import('ngeo/query/Querent.js').QuerentResult} response Response
 * @private
 */
MapQuerent.prototype.handleResult_ = function(action, response) {
    let total = action === ngeoQueryAction.REPLACE ? 0 : this.result_.total;

    // (1) Update result sources, i.e. add them
    for (const idStr in response) {
      const id = Number(idStr);
      const dataSource = this.ngeoDataSourcesHelper_.getDataSource(id);
      if (!dataSource) {
        throw new Error('Missing dataSource');
      }
      let label = dataSource.name;
      console.assert(dataSource);

      const querentResultItem = response[id];
      const features = querentResultItem.features;
      const limit = querentResultItem.limit;
      const tooManyResults = querentResultItem.tooManyFeatures === true;
      const totalFeatureCount = querentResultItem.totalFeatureCount;
      const requestPartners = querentResultItem.requestPartners;

      /** @type {Object<string, Array<import('ol/Feature.js').default<import("ol/geom/Geometry.js").default>>>} */
      const typeSeparatedFeatures = {};
      features.forEach((feature) => {
        /** @type {string} */
        const type = feature.get('ngeo_feature_type_');
        if (!typeSeparatedFeatures[type]) {
          typeSeparatedFeatures[type] = [];
        }

        // ================ OVERRIDE ===================
        // Use properties aliases if any
        setFeaturePropertiesWithAliases(dataSource, feature, type);
        // ================ OVERRIDE END ===================

        typeSeparatedFeatures[type].push(feature);
      });

      for (const type in typeSeparatedFeatures) {
        label = type ? type : label;
        const featuresByType = typeSeparatedFeatures[type];
        let shouldPush = false;

        if (action === ngeoQueryAction.REPLACE) {
          shouldPush = true;
        } else {
          let existingSource;
          for (const source of this.result_.sources) {
            if (source.id === id && source.label === label) {
              existingSource = source;
              break;
            }
          }

          if (existingSource) {
            for (const newFeature of featuresByType) {
              const existingFeatureIndex = this.featureHelper_.findFeatureIndexByFid(
                existingSource.features,
                `${newFeature.getId()}`
              );
              if (existingFeatureIndex === -1) {
                if (action === ngeoQueryAction.ADD) {
                  existingSource.features.push(newFeature);
                  total += 1;
                }
              } else {
                if (action === ngeoQueryAction.REMOVE) {
                  existingSource.features.splice(existingFeatureIndex, 1);
                  total -= 1;
                }
              }
            }
          } else {
            if (action === ngeoQueryAction.ADD) {
              shouldPush = true;
            }
          }
        }

        if (shouldPush) {
          this.result_.sources.push({
            features: featuresByType,
            id: id,
            label: label,
            limit: limit,
            pending: false,
            tooManyResults: tooManyResults,
            totalFeatureCount: totalFeatureCount,
            identifierAttributeField: dataSource.identifierAttribute,
            requestPartners: requestPartners,
          });
          total += features.length;
        }
      }
    }

    // (2) Update total & pending
    this.result_.total = total;
    this.result_.pending = false;
    this.cleared_ = false;
};

// ======== Display results stop ========

